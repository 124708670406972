export const homeQuery = `// groq
        *[_type == 'home'][0] {
        title,
        seo {
            description,
            keywords,
            title
        },
        introSection {
            title,
            introBackgroundImage {
            asset-> {
                url
            }
            }
        },
        aboutUsSection {
            title,
            subTitle,
            description,
            aboutUsBackgroundImage {
            asset-> {
                url
            }
            }
        },
        serviceSection{
          title,
          accordion{
          title,
          accordionItem[]{
            title,
            "_rawDescription": description
           }
         }
        },
        industrySection {
            title,
            description,
            industryImage {
            asset-> {
            url
            }
            }
        },
        careerSection {
            quotation,
            provider
        },
        getInTouchSection
        }
`;
