import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { HomeIntro, HomeIndustry, HomeAboutUs, HomeService, HomeCareer, HomeGetInTouch, SEO } from '../components';
import { SanityHomeType } from '../utils/globalTypes';
import { SEO_TEXT } from '../constants';
import { getClient } from '../utils/sanity.client';
import { homeQuery } from '../utils/querys/home/query';
import { Router } from '@reach/router';

// @ts-ignore
const IndexPage = ({ data: { sanityHome } }: Props) => {
  const [homeData, setHomeData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(homeQuery);
      setHomeData(data);
    };
    if (homeData === undefined && url.search === '?preview_mode=true') getData();
  }, [homeData]);
  return (
    <Fragment>
      <SEO title={sanityHome.seo?.title || ''} description={sanityHome.seo?.description || ''} />
      <HomeIntro content={homeData ? homeData.introSection : sanityHome.introSection} />
      <HomeAboutUs content={homeData ? homeData.aboutUsSection : sanityHome.aboutUsSection} />
      <HomeService content={homeData ? homeData.serviceSection : sanityHome.serviceSection} />
      <HomeIndustry content={homeData ? homeData.industrySection : sanityHome.industrySection} />
      <HomeCareer content={homeData ? homeData.careerSection : sanityHome.careerSection} />
      <HomeGetInTouch content={homeData ? homeData.getInTouchSection : sanityHome.getInTouchSection} />
    </Fragment>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    sanityHome {
      seo {
        description
        keywords
        title
      }
      introSection {
        title
        introBackgroundImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      aboutUsSection {
        title
        subTitle
        description
        aboutUsBackgroundImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      serviceSection {
        title
        accordion {
          title
          accordionItem {
            title
            _rawDescription
          }
        }
      }
      industrySection {
        title
        description
        industryImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      careerSection {
        quotation
        provider
      }
      getInTouchSection {
        getInTouch {
          description
          title
          buttonLabel
        }
      }
    }
  }
`;
